<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
            <v-card-title>
                <span class="text-h5">{{ itemToEdit.action_name }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="12">
                                <div style="display: flex; align-items: center;">
                                    <span style="margin-left: 10px; font-weight: 600;">סטטוס: </span>
                                    <v-switch color="success" v-model="itemEdited.mode" inset hide-details
                                        style="margin-top: 0px"></v-switch>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.url" outlined dense label="webhook"
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <div style="display: flex; justify-content: center !important; padding-bottom: 25px;">
                <v-btn :color="'gradient-yellow-button-background'" style="margin-left: 15px; font-size: medium;"
                    @click="saveEditedApi()">
                    שמור שינויים
                </v-btn>
                <v-btn class="gradient-button-background" @click="openMode = false" style="font-size: medium;">
                    ביטול
                </v-btn>
            </div>

        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        itemToEdit: Object,
        value: { type: Boolean, default: false },

    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            action_name: "",
            mode: "",
            url: "",
        },
        sendCall: false,
        roles: ["user", "admin"]
    }),
    methods: {
        async saveEditedApi() {
            console.log(1111111111111);
            const datesJSON = JSON.stringify({ item: this.itemEdited });
            this.progressShow = true;
            let token = localStorage.getItem("token");

            try {1
                let api = process.env.VUE_APP_BASE_URL + "/settings/edit_api_integration";
                const res = await fetch(api, ApiServices.requestOptions("PUT", datesJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.$emit("apiEdited", "", "error: " + jsonObject, "red");
                } else if (res.status === 200) {
                    this.$emit("apiEdited", this.itemEdited, "השינויים נשמרו בהצלחה!", "green");
                    this.openMode = false;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error saved list: " + error, "red");

            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        console.log(this.itemEdited);
    },
}
</script>
<style scoped>
.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}
</style>
  