<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
            <v-card-title class="text-h6">האם אתה בטוח שברצונך לשלוח צינתוק לכל החברים?</v-card-title>
            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="'gradient-button-background'" style="margin-left: 15px; font-size: medium;"
                    @click="closeDelete">
                    ביטול
                </v-btn>
                <v-btn :color="'gradient-yellow-button-background'" style="margin-left: 15px; font-size: medium;"
                    @click="sendCallToAll">
                    אישור
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
    }),

    methods: {
        async sendCallToAll() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/subscribes/send_call_to_all_subscribers";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בשליחת הצינתוק", "red")
                    this.progressShow = false;
                } else if (res.status === 200) {
                    this.showSnackBar("הפעולה בוצעה בהצלחה!", "green")
                    this.progressShow = false;
                    setTimeout(() => {
                        this.openMode = false;
                    }, 1500);
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("שגיאה בשליחת הצינתוק: " + error, "red")
            }
        },
        closeDelete() {
            this.openMode = false;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
<style scoped>
.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}
</style>
    