<template>
    <v-dialog v-model="openMode" max-width="400px">
        <v-card
            style="padding-left: 30px; padding-right: 30px; background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
            <div style="text-align: center; padding: 5px;">
                <span class="text-h5">ערוך מספר {{ itemEdited.line_number }}</span>
            </div>

            <v-row no-gutters style=" margin-top: 40px; margin-bottom: 20px;">
                <v-col cols="12" sm="6">
                    <v-select :items="types" v-model="itemEdited.type" hide-details outlined hide-spin-buttons dense
                        label="סוג" class="negative-elevation" background-color="white"></v-select>
                </v-col>
            </v-row>
            
            <v-row no-gutters style="margin-bottom: 10px;">
                <v-col cols="12" sm="6">
                    <v-text-field v-model="itemEdited.belong_to_agent" hide-details outlined type="number" hide-spin-buttons
                        dense label="שייך לסוכן" class="negative-elevation" background-color="white"></v-text-field>
                </v-col>
            </v-row>

            <div style="display: flex; justify-content: start !important; padding-top: 20px; padding-bottom: 20px;">
                <v-btn :color="'gradient-yellow-button-background'" style="margin-left: 15px;" @click="addNumbers">שמור
                    שינויים</v-btn>
                <v-btn :color="'gradient-button-background'" @click="closeDelete">סגור</v-btn>
            </div>

            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>

        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        value: { type: Boolean, default: false },
        editNumber: { type: Object },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {},
        types: ["מספר כללי", "פתיחת מערכת"],
    }),

    methods: {
        async addNumbers() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const itemsJSON = JSON.stringify({ number: this.itemEdited});

                let api = process.env.VUE_APP_BASE_URL + "/admin_api/edit_number_agent";
                const res = await fetch(api, ApiServices.requestOptions("POST", itemsJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400 || res.status === 500) {
                    this.showSnackBar("שגיאה בשמירת השינויים: " + jsonObject, "red");
                } else if (res.status === 200) {
                    this.$emit("numberAgentChange", this.itemEdited, "השינויים בוצעו בהצלחה", "green");
                    this.closeDelete();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("שגיאה בשמירת שינויים : " + error, "red");
            }
        },
        closeDelete() {
            this.openMode = false;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {  
            this.itemEdited = { ...this.editNumber};     
    },
}
</script>
<style scoped>
.negative-elevation {
    /* Example of a custom style to mimic negative elevation */
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Other styles can be added here as needed */
}


.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}


.scrollbar-numbers-style::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.scrollbar-numbers-style::-webkit-scrollbar-thumb {
    background-color: #183677;
}

.scrollbar-numbers-style::-webkit-scrollbar {
    width: 10px;
    background-color: #949494;
}

.scrollbar-numbers-style::-webkit-scrollbar {
    height: 0;
}

:not(.scrollbar-numbers-style)::-webkit-scrollbar {
    width: 0px !important;
}
</style>
    