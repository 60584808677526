<template>
  <div class="mt-5">

    <form action="" @submit.prevent="update" class="container form-responsive rounded border p-4"
      style="max-width: 500px; background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%); border: 1px solid #214883;">
      <div class="text-center border fw-bold my-4 p-4 bg-light">
        <p>
        <h4>טופס איפוס סיסמה </h4>
        </p>
      </div>

      <v-row>
        <v-col cols="12">
          <v-text-field v-model="ressetPass.newPassword" label="סיסמה חדשה" :rules="[v => !!v || 'שדה חובה']"
            required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="ressetPass.againPassword" label="אימות סיסמה" :rules="[v => !!v || 'שדה חובה']"
            required></v-text-field>
        </v-col>
        <div class="my-5  text-center">
          <v-btn color="rgba(255,185,26,1)"  class="ms-5" dark type="submit">
            שמור סיסמה
          </v-btn>
        </div>
        <div class="d-flex justify-content-center mb-3">
          <div class="spinner-border text-danger" role="status" v-if="showSpinner">
            <span class="sr-only"></span>
          </div>
        </div>
      </v-row>
    </form>

    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>


  </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'

export default {

  components: {
  },
  data() {
    return {
      showSpinner: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      host: "",
      ressetPass: {
        newPassword: "",
        againPassword: "",
      },
    }
  },
  methods: {

    async update() {

      if (this.ressetPass.newPassword.length < 9 || this.ressetPass.againPassword.length < 9) {
        this.showSnackBar("שגיאה, הסיסמה חייבת להכיל 9 ספרות", "red");
        return
      }
      if (this.ressetPass.newPassword !== this.ressetPass.againPassword) {
        this.showSnackBar("הסיסמאות אינם תואמות", "red");
        return
      }
      this.showSpinner = true;
      let MyJSON = JSON.stringify({ newPassword: this.ressetPass.newPassword });

      let token = this.$route.params.token;
      this.showSpinner = true;

      try {
        let api = process.env.VUE_APP_BASE_URL + "/auth/resetPassword";
        const res = await fetch(api, ApiServices.requestOptions("PUT", MyJSON, token));
        this.showSpinner = false;
        if (res.status === 400) {
          const jsonObject = await res.json();
          this.showSnackBar("Error saved password: " + jsonObject.errors[0].message, "red");
        } else if (res.status === 200) {
          this.showSnackBar("הסיסמה שונתה בהצלחה", "green");
          await this.sleep(1500);
          window.location.pathname = `/`;
        }
      } catch (error) {
        this.showSpinner = false;
        this.showSnackBar("Error saved pass: " + error, "red");
      }

    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  created() {

  },
}
</script>

    