<template >
    <div>

        <div style="margin-bottom: 20px; margin-right: 20px;">

            <v-row style="margin-top: 20px;">
                <span style="font-size: large; font-weight: 600; margin-right: 14px; ">זמן צלצול בשניות:</span>
                <div style="margin-right: 65px;">
                    <v-select background-color="white" :items="ring_times" v-model="details.ring_time" outlined dense style="max-width: 80px;"
                        ></v-select>
                </div>

            </v-row>

            <v-row style="margin-top: 20px;">
                <span style="font-size: large; font-weight: 600; margin-right: 14px; ">מספר זיהוי בשיחה יוצאת:</span>
                <div style="margin-right: 10px;">
                    <v-select background-color="white" :items="details.other_lines" v-model="details.out_calls_id" outlined dense style="max-width: 220px;"
                        ></v-select>
                </div>

            </v-row>

            <v-row>
                <span style="font-size: large; font-weight: 600; margin-right: 14px;">השתק כולל הודעות פרטיות:</span>
                <div>
                    <v-radio-group v-model="groupValue" style="margin-top:0px; padding-top: 0px; margin-right: 10px;">
                        <v-radio v-for="n in groupType" color="#0d2c6d" hide-details :key="n" :label="`${n}`"
                            :value="n"></v-radio>
                    </v-radio-group>
                </div>

            </v-row>

            <v-row>
                <span style="font-size: large; font-weight: 600; margin-right: 14px;">השתק התראות עד לתאריך:</span>
                <div style="margin-right: 10px;">
                    <v-text-field background-color="white" v-model="details.system_mute.mute_until" outlined dense
                        hide-details type="datetime-local"></v-text-field>
                </div>

            </v-row>

            <v-row>
                <v-col cols="12" style="padding-left: 0px; padding-top: 35px;">
                    <span style="font-size: large; font-weight: 600;">הגדר זמן קבוע להשתקת צינתוק:</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                </v-col>
                <v-col cols="12" md="4">
                    <v-row style="background-color: #d4d3d3; ">
                        <v-col cols="4" md="2" style="display: flex; justify-content: center;">
                            <span style="font-size: large;">יום</span>
                        </v-col>
                        <v-col cols="4" md="3" style="display: flex; justify-content: center;">
                            <span style="font-size: large;">מצב</span>
                        </v-col>
                        <v-col cols="4" md="7" style="display: flex; justify-content: center;">
                            <span style="font-size: large;">בין השעות</span>
                        </v-col>
                    </v-row>

                    <div v-for="([dayName, dayArray]) in Object.entries(details.system_mute.every_day_mute)"
                        style="padding-top: 13px; margin-top: 10px; border: 1px solid #ccc;">
                        <v-row v-for="(dayObjects, dayObjIndex) in dayArray" style="background-color: #f0efef;">
                            <v-col cols="auto" sm="2" style="display: flex; justify-content: center;">
                                <span style="font-size: large;">{{ dayObjects.hebrewDay }}</span>
                            </v-col>
                            <v-col cols="auto" sm="3" style="display: flex; justify-content: center;">
                                <v-switch color="success" v-model="dayObjects.status" inset hide-details
                                    style="margin-top: 0px;"></v-switch>
                            </v-col>
                            <v-col cols="auto" sm="7" style="padding-top: 0px; padding-bottom: 0px;">
                                <div style="height: 100%; display: flex;">
                                    <div style="height: 100%; display: flex; justify-content: center; align-items: center;">
                                        <v-text-field background-color="white" v-model="dayObjects.startTime" hide-details
                                            outlined dense type="time"></v-text-field>
                                    </div>
                                    <div>
                                        <span
                                            style="height: 100%; margin-left: 10px; margin-right: 10px; display: flex; justify-content: center; align-items: center;">עד</span>
                                    </div>
                                    <div style="height: 100%; display: flex; justify-content: center; align-items: center;">
                                        <v-text-field background-color="white" v-model="dayObjects.endTime" outlined dense
                                            hide-details type="time"></v-text-field>
                                    </div>

                                    <div v-if="dayObjIndex == 0"
                                        style="height: 100%; display: flex; align-items: center; margin-right: 10px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <img src="@/assets/settings/icon_add.svg" v-on="on"
                                                    style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;"
                                                    class="audioButtons"
                                                    @click="addDateMute(dayName, dayObjects.hebrewDay, dayArray.length)">
                                            </template>
                                            <span>הוסף זמנים</span>
                                        </v-tooltip>
                                    </div>

                                    <div v-if="dayObjIndex != 0"
                                        style="height: 100%; display: flex; align-items: center; margin-right: 10px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <img src="@/assets/upload/icon_delete.svg" v-on="on"
                                                    style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;"
                                                    class="audioButtons" @click="deleteDateMute(dayName, dayObjIndex)">
                                            </template>
                                            <span>מחק זמנים</span>
                                        </v-tooltip>
                                    </div>
                                </div>

                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

            <v-row style="margin-bottom: 70px; margin-top: 80px; margin-right: 20px; ">
                <v-col cols="12">
                    <v-btn class="gradient-yellow-button-background" style="margin-left: 15px; font-size: medium;"
                        @click="saveChanges()">
                        שמור שינויים
                    </v-btn>
                </v-col>

            </v-row>
        </div>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Util from '@/util/utils.js'

export default {
    components: {
        SnackBar,
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        details: {},
        days: [],
        groupType: ["כן", "לא"],
        groupValue: "",
        ring_times: [5, 10, 15, 20, 25],
    }),
    methods: {
        async getSettingsDetails() {
            try {

                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/get_system_mute_details`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status === 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.details = jsonObject;
                    this.formatSettings();
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatSettings() {
            if (this.details.system_mute.mute_include_privates) {
                this.groupValue = "כן"
            } else {
                this.groupValue = "לא"
            }

            if (this.details.system_mute.mute_until) {
                this.details.system_mute.mute_until = Util.getFormatDate(this.details.system_mute.mute_until)
            }

        },
        addDateMute(dayName, hebrewDay, dayArrayLengh) {
            if (dayArrayLengh < 3) {
                this.details.system_mute.every_day_mute[dayName].push({ startTime: "", endTime: "", status: false, hebrewDay: hebrewDay })
            }

        },
        deleteDateMute(dayName, dayObjectIndex) {
            this.details.system_mute.every_day_mute[dayName].splice(dayObjectIndex, 1);
        },
        async saveChanges() {

            try {
                const everyDaysDetails = this.details.system_mute.every_day_mute;
                for (const key in everyDaysDetails) {
                    if (Object.hasOwnProperty.call(everyDaysDetails, key)) {
                        const dayArray = everyDaysDetails[key];

                        // Check each object in the day's array
                        for (const dayObject of dayArray) {
                            const startTime = dayObject.startTime;
                            const endTime = dayObject.endTime;

                            if ((startTime && !endTime) || (!startTime && endTime)) {
                                this.showSnackBar("חסר נתונים ביום " + dayObject.hebrewDay, "red");
                                return;
                            }
                        }
                    }
                }

                if (this.groupValue === "כן") {
                    this.details.system_mute.mute_include_privates = true;
                } else {
                    this.details.system_mute.mute_include_privates = false;
                }

                this.progressShow = true;
                const detailsJSON = JSON.stringify({ updatedObj: this.details });
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/save_mute_changes`;
                const res = await fetch(api, ApiServices.requestOptions("PUT", detailsJSON, token));
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בשמירת השינויים", "red");
                } else if (res.status === 200) {
                    this.showSnackBar("השינויים עודכנו בהצלחה!", "green");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error save changes: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    created() {
        this.getSettingsDetails();
    },
}
</script>
<style ></style>
    