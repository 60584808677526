<template >
    <v-container>

        <div style="margin-right: 5%;">
            <v-row class="box" style="padding-right: 32px; padding-left: 32px;  width: 70%;">
                <v-col cols="12" class="box-style">
                    <v-row style="display: flex; align-items: center;">
                        <v-col cols="12" sm="3">
                            <img src="@/assets/settings/plan-4.svg">
                        </v-col>
                        <v-col cols="12" sm="6">
                            <div style="display: flex; flex-direction: column !important; align-items: center;">
                                <span class="span-content-large" style="font-weight: 600;">התכנית שלכם</span>
                                <span style="font-size: x-large; color: #0d2c6d; font-weight: 600;">{{
                                    planDetails.name }}</span>

                                <span style="font-size: x-large; color: #0d2c6d;">אתם בתכנית<span
                                        style="font-weight: 600; margin-right: 5px; color: #0d2c6d; font-size: x-large;">{{
                                            getStatusPlan
                                        }}</span></span>
                                <div v-if="planDetails.tryingFinishDate && !planDetails.hasHok">
                                    <span style="font-size: small;">סיום תקופת הניסיון {{
                                        planDetails.tryingFinishDate }}</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn :color="'gradient-yellow-button-background'" @click="upgradePlan()">שדרג חבילה</v-btn>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
            <v-row class="box" style="padding-right: 20px; padding-left: 20px;  width: 70%;">
                <v-col cols="12" sm="6">
                    <div class="box-style" style="padding: 10px; display: flex; align-items: center; height: 100px;">
                        <img src="@/assets/settings/plan-8.svg">
                        <div style="display: flex; flex-direction: column; margin-right: 30px;">
                            <span class="span-content-large">מועד החיוב הבא</span>
                            <span class="span-content-large-bold">{{ formatNextRenew() }}</span>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6">
                    <div class="box-style" style="padding: 10px; display: flex; align-items: center; height: 100px;">
                        <img src="@/assets/settings/plan-3.svg">
                        <div style="display: flex; flex-direction: column; margin-right: 30px;">
                            <span class="span-content-large">תשלום</span>
                            <span class="span-content-large-bold">{{ planDetails.price }} ₪ לחודש</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row class="box" style="padding-right: 20px; padding-left: 20px;  width: 70%;">
                <v-col cols="12" sm="6">
                    <div class="box-style" style="padding: 10px; display: flex; align-items: center; height: 100px;">
                        <img src="@/assets/settings/plan-9.svg" style="padding: 5px;">
                        <div style="display: flex; flex-direction: column; margin-right: 30px;">
                            <span class="span-content-large">ערוצים</span>
                            <span class="span-content-large-bold">{{ planDetails.channels }}</span>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6">
                    <div class="box-style" style="padding: 10px; display: flex; align-items: center; height: 100px;">
                        <img src="@/assets/settings/plan-7.svg">
                        <div style="display: flex; flex-direction: column; margin-right: 30px;">
                            <span class="span-content-large">אנשי קשר</span>
                            <span class="span-content-large-bold">{{ planDetails.contacts }}</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row class="box" style="padding-right: 20px; padding-left: 20px;  width: 70%;">
                <v-col cols="12" sm="5" md="4">
                    <div class="box-style" style="padding: 10px; display: flex; align-items: center; height: 100px;">
                        <img src="@/assets/settings/plan-2.svg">
                        <div style="display: flex; flex-direction: column; margin-right: 30px;">
                            <span class="span-content-large">אחסון</span>
                            <span class="span-content-large-bold">{{ planDetails.storage }}GB</span>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                    <div class="box-style" style="padding: 10px; display: flex; align-items: center; height: 100px;">
                        <img src="@/assets/settings/plan-3.svg">
                        <div style="display: flex; flex-direction: column; margin-right: 30px;">
                            <div>
                                <span class="span-content-large">{{ storageFormated }} מתוך {{ planDetails.storage
                                }}GB</span>
                            </div>
                            <div style="width: 400px; max-width: 100%;">
                                <v-progress-linear :value="progressStorage" color="#ffb91a" background-color="white" rounded
                                    height="10" class="storage-progress-bar"></v-progress-linear>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>

        </div>

        <v-row v-if="progressShow" style="margin-top: 20px;">
            <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
            </v-col>
        </v-row>


        <PlansDialog v-model="plansDialog" v-if="plansDialog" />


        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Util from '@/util/utils.js'
import PlansDialog from '@/components/settingspage/dialogs/PlansDialog'

export default {
    components: {
        SnackBar,
        PlansDialog

    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        progressShow: false,
        snacbarText: "",
        storageFormated: "0",
        plansDialog: false,
        planDetails: {},
        getStatusPlan: "תקופת ניסיון",
        getNextBillingPeriod: "",
        progressStorage: 50, // Set the progress value here, as a percentage
    }),
    methods: {

        async getPlanDetails() {
            try {

                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/get_plan_details`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status === 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.planDetails = jsonObject;
                    console.log(this.planDetails);
                    this.progressShow = false;
                    this.setStorageInUsed();
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        setStorageInUsed() {
            const storageInUsed = this.planDetails.totalStorageUsed;
            // Check if the storage is greater than 1000
            if (storageInUsed > 1000) {
                // Convert to GB and keep one decimal place
                const sizeInGB = (storageInUsed / 1000).toFixed(1);
                this.storageFormated = sizeInGB + "GB";
            } else {
                // Otherwise, display in MB
                this.storageFormated = storageInUsed + "MB";
            }

            // Convert storage limit from GB to MB
            const storageLimit = this.planDetails.storage * 1000;
            // Calculate the percentage of storage used
            this.progressStorage = (storageInUsed / storageLimit) * 100;
            // Optionally, format the percentage to a fixed number of decimal places
            this.progressStorage = this.progressStorage.toFixed(2); // For example, 2 decimal places

            // set status plan
            if (this.planDetails.hasHok) {
                this.getStatusPlan = "תשלום חודשי"
                this.getNextBillingPeriod = "---"
            } else {
                this.getStatusPlan = "תקופת ניסיון"
                this.planDetails.tryingFinishDate = Util.getCurrentDate(this.planDetails.tryingFinishDate);
            }

        },
        formatNextRenew() {
            if (!this.planDetails.startPayDate) {
                return "---";
            }
            const createdDate = new Date(this.planDetails.startPayDate);

            const currentDate = new Date(); // Get the current date
            // Set the month of createdDate to the current month
            createdDate.setMonth(currentDate.getMonth());
            // Add one month to the createdDate
            createdDate.setMonth(createdDate.getMonth() + 1);
            return createdDate.toLocaleDateString();
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        upgradePlan() {
            this.plansDialog = true;
        }
    },
    mounted() {
        this.getPlanDetails();
    },
}
</script>
<style>
.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}


.div-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.span-content-large {
    color: #0d2c6d;
    font-weight: 500;
    font-size: large;
}

.span-content-large-bold {
    color: #0d2c6d;
    font-weight: bold;
    font-size: large;
}

.span-content-small {
    color: #a5a3a3;
    font-weight: 500;
    font-size: 15px;
}

.box-style {
    background-color: #eee;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.storage-chip {
    position: absolute;
    top: -14px;
    /* Adjust the value to align the chip above the progress bar */
    left: 50%;
    /* Adjust if needed */
    transform: translateX(-50%);
}

.storage-progress-bar {
    margin-top: 20px;
    /* Make space for the chip */
}


@media (max-width: 600px) {
    /* Example for small screens */


    .box {
        width: 100% !important;
        /* Full width on small screens */
        margin: 10px !important;
        /* Space between boxes */
        flex-direction: column !important;
        padding: 5px !important;
    }
}
</style>
    