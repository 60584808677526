<template >
    <v-container>

        <v-card style="background-color: #F3F6F9;">
            <div class="padding-responsive">
                <div class="padding-responsive" style="background-color: white;">
                    <span style="font-size: 24px; color: #6A707E;">פרטי חבילות</span>
                    <div style="display: flex; align-items: center; direction: ltr; margin-left: 6%; margin-bottom: 20px;">
                        <v-btn :color="'gradient-yellow-button-background'" @click="openCreateDIalog"
                            style="margin-left: 20px; margin-right: 20px; font-weight: 500; font-size: medium;">הוסף
                            חבילה</v-btn>
                    </div>
                    <v-row class="margin-responsive" :class="{ 'd-flex justify-space-around': plans.length > 2 }">
                        <v-col cols="auto" sm="6" md="3" v-for="(item, index) in plans">

                            <div class="box_credit_buy_package"
                                style="width: 350px; max-width: 100%; background-color: white;">

                                <div
                                    style="padding: 15px; display: flex; justify-content: center; background-color: #F3F6F9; ">
                                    <span :style="{ color: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }"
                                        style="font-size: 22px;">{{ item.name
                                        }}</span>
                                </div>

                                <div :style="{ backgroundColor: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }"
                                    style="height: 2px;"></div>

                                <div style="display: flex; justify-content: center;">
                                    <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל אנשי קשר בחבילה:
                                        {{ item.channels
                                        }}</span>
                                </div>
                                <div style="display: flex; justify-content: center;">
                                    <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל אחסון בחבילה: {{
                                        item.storage }}GB</span>
                                </div>
                                <div style="display: flex; justify-content: center;">
                                    <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל ערוצים בחבילה:
                                        {{
                                            item.channels }}</span>
                                </div>

                                <div style="display: flex; justify-content: center;">
                                    <span style="margin-top: 20px; font-size: 18px; color: #6A707E;">החבילה בשימוש:
                                        {{ item.active_users }} משתמשים</span>
                                </div>

                                <div style="display: flex; justify-content: center;">
                                    <span style="margin-top: 10px; font-size: 50px;"
                                        :style="{ color: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }"><span
                                            style="font-size: medium; font-weight: 600;">₪</span>{{ item.price }}</span>
                                </div>

                                <div
                                    style="background-color: #edf2f7; display: flex; align-items: center; justify-content: space-around; height: 50px;">
                                    <v-tooltip bottom class="ml-2" v-if="item.show_to_user">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="green" dark v-bind="attrs" v-on="on">
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>מוצג ללקוח</span>
                                    </v-tooltip>
                                    <v-tooltip bottom class="ml-2" v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="red" dark v-bind="attrs" v-on="on">
                                                mdi-eye-off
                                            </v-icon>
                                        </template>
                                        <span>מוסתר ללקוח</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" :color="index % 2 === 0 ? '#0d2c6d' : '#fdbd29'" medium
                                                class="me-3" @click="deletePlan(item)">
                                                mdi-delete-outline
                                            </v-icon>
                                        </template>
                                        <span>מחיקה</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" :color="index % 2 === 0 ? '#0d2c6d' : '#fdbd29'" medium
                                                class="me-3" @click="openEditDialog(item)">
                                                mdi-square-edit-outline
                                            </v-icon>
                                        </template>
                                        <span>עריכה</span>
                                    </v-tooltip>


                                </div>
                            </div>
                        </v-col>

                    </v-row>

                </div>
            </div>
        </v-card>

        <v-row v-if="progressShow" style="margin-top: 20px;">
            <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
            </v-col>
        </v-row>


        <PlansAddEditDialog v-model="editPlanDialog" v-if="editPlanDialog" :planToEdit="planToEdit" :formTitle="formTitle"
            @planEdited="planEditedSaved" @planCreated="newPlanSaved" />

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Util from '@/util/utils.js'
import PlansAddEditDialog from '@/components/admin/dialogs/PlansAddEditDialog'

export default {
    components: {
        SnackBar,
        PlansAddEditDialog

    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        plans: [],
        planToEdit: {},
        formTitle: "",
        editPlanDialog: false
    }),
    methods: {
        actionConfirmed() {
            this.deleteItem();
        },

        async getAllPlans() {
            try {

                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/admin_api/get_plans_for_edit`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status === 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.plans = jsonObject;
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        openEditDialog(item) {
            this.planToEdit = item;
            this.formTitle = "ערוך חבילה"
            this.editPlanDialog = true;
        },
        openCreateDIalog() {
            this.formTitle = "הוסף חבילה"
            this.planToEdit = {}
            this.editPlanDialog = true
        },
        closeDelete() {
            this.openMode = false;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        planEditedSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.plans.findIndex(plan => plan._id === item._id);

            let g = Object.assign(this.plans[index], item);
        },
        newPlanSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            this.plans.push(item);

        },
        async deletePlan(item) {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const planJSON = JSON.stringify({ plan: item });
                let api = process.env.VUE_APP_BASE_URL + `/admin_api/delete_plan`;
                const res = await fetch(api, ApiServices.requestOptions("DELETE", planJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה במחיקת החבילה: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.showSnackBar("החבילה נמחקה בהצלחה!", "green");
                    // Use splice to remove the item at the specified index
                    const index = this.plans.findIndex(plan => plan._id === item._id);
                    this.plans.splice(index, 1);
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        }

    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.getAllPlans();
    },
}
</script>
<style>
.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.box_credit_buy_package {
    box-shadow: -1px 3px 5px 1px rgb(240 240 240);
    -webkit-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
    -moz-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
    border-radius: 5px;
}

.padding-responsive {
    padding: 10px;
}

@media (min-width: 600px) {
    .padding-responsive {
        padding: 30px;
    }

    .margin-responsive {
        margin: 10px;
    }
}
</style>
    