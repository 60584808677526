<template >
    <div style="margin-top: 100px;">
        <div style=" width: 100%; margin-bottom: 20px; border: 1px solid #dad7d7; display: none;" id="allert-upload">
            <span style="color: green; font-weight: bold; font-size: medium;">
            </span>
        </div>

        <v-row style="margin-right: 6%; display: flex; align-items: center;">
            <v-col cols="12" md="3" sm="6">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                    class="search">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
                <span style="margin-inline-start: 10px; font-weight: 600;">סך הכל {{ calls.length }} שיחות אונליין</span>
            </v-col>
        </v-row>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">


                <v-data-table id="table1" :headers="headers" :items="calls" sort-by="call_duration" :search="search"
                    :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ index + 1 }}</td>
                            <td class="table-cells">{{ item.callerName }}</td>
                            <td class="table-cells">{{ item.calleridnum }}</td>
                            <td class="table-cells">{{ item.accountcode }}</td>
                            <td class="table-cells">{{ item.ivrNumber }}</td>
                            <td class="table-cells">{{ item.context }}</td>
                            <td class="table-cells">{{ item.duration }}</td>
                            <td class="table-cells">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/onlineCalls/hangup.svg" v-on="on"
                                            style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;"
                                            class="audioButtons" @click="hangupCall(item, index)">
                                    </template>
                                    <span>ניתוק שיחה</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.actions="{ item, index }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/onlineCalls/hangup.svg" v-on="on"
                                    style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;" class="audioButtons"
                                    @click="hangupCall(item, index)">
                            </template>
                            <span>ניתוק שיחה</span>
                        </v-tooltip>
                    </template>


                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו שיחות</span>
                        </div>
                    </template>

                </v-data-table>


            </div>
        </div>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import utils from '@/util/utils'
import Auth from '@/services/auth.service.js'

export default {
    components: {
        SnackBar,

    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        search: "",
        intervalId: null, // Add a property to store the interval ID
        // calls: [{ accountcode: "eeee", callerName: "rgrgr" }],
        calls: [],
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'שם', align: 'start', value: 'callerName' },
            { text: 'מספר המתקשר', value: 'calleridnum' },
            { text: 'יעד התקשרות', value: 'accountcode'},
            { text: 'יעד ראשי', value: 'ivrNumber' },
            { text: 'שלוחה/מיקום האזנה', value: 'context' },
            { text: 'זמן שיחה', value: 'duration' },
            { text: '', value: 'actions', sortable: false },
        ],
    }),
    methods: {

        async getOnlineCalls() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/online_calls/get_online_calls`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.progressShow = false;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.calls = jsonObject;
                    // console.log(channel);
                    // this.formatDetails();
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.records = this.records.map((record) => {
                return {
                    ...record,
                    createdAt: utils.getCurrentDate(record.createdAt),
                    record_duration: this.formatDuration(record.record_duration),
                }
            });
        },

        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;

            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = sec.toString().padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async hangupCall(item, index) {
            try {
                let token = localStorage.getItem("token");
                const channelJSON = JSON.stringify({ channel: item.channel });
                let api = process.env.VUE_APP_BASE_URL + `/online_calls/hangup_call`;
                const res = await fetch(api, ApiServices.requestOptions("POST", channelJSON, token));
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בניתוק השיחה", "red");
                } else if (res.status === 200) {
                    // Use splice to remove the item at the specified index
                    const index = this.calls.findIndex(call => call.channel === item.channel);
                    this.calls.splice(index, 1);
                    this.showSnackBar("השיחה נותקה", "green");
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        }

    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        // const socket = io(process.env.VUE_APP_BASE_URL);
        // socket.on('onlinCalls', (data) => {
        //     console.log(2222222222);
        //     // 'data' will contain the details sent from the server
        //     console.log('Received onlinCalls event with data:', data);
        //     this.calls = data;

        //     // You can update your Vue component's data or perform any other necessary actions with the received data here
        // });
        this.getOnlineCalls();
        this.intervalId = setInterval(() => {
            this.getOnlineCalls();
        }, 5000);

    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
}
</script>
<style scoped>
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */


#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    