<template >
    <div>

        <div v-if="allertDelete"
            style="border: 1px solid #c88383; padding: 10px; opacity: 1; display: block; width: 90%; margin-right: 5%; margin-bottom: 10px;">
            <span style="color: #882626; font-weight: 600;">קבצים זמניים יימחקו לצמיתות לאחר 30 יום</span>
        </div>

        <div style=" width: 90%; margin-right: 4.5%; margin-bottom: 10px;">
            <v-row style="display: flex; align-items: center;">
                <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                    <div style="display: flex; align-items: center;">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                            style="margin-left: 20px; margin-right: 20px;" class="search">
                        </v-text-field>
                        <span style=" font-weight: 600;">סך הכל {{ records.length }} קבצים זמניים</span>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">

                <v-data-table id="table1" :headers="headers" :items="records" sort-by="position" sort-desc :search="search"
                    :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ item.integer_id }}</td>
                            <td class="table-cells">{{ item.caller_name }}</td>
                            <td class="table-cells">{{ item.caller_id }}</td>
                            <td class="table-cells">{{ item.type }}
                                <v-tooltip bottom color="#0d2c6d" v-if="item.type === 'הודעה משותפת'">
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/upload/icon_details.svg" v-on="on" style="cursor: pointer;">
                                    </template>
                                    <span style="color: white;">שותף ממערכת מספר: {{ item.shared_from_ivr }}</span>
                                </v-tooltip>
                            </td>
                            <td class="table-cells">{{ item.createdAt }}</td>
                            <td class="table-cells">{{ item.record_duration }}</td>
                            <td class="table-cells">{{ item.record_name_original }}</td>
                            <td class="table-cells">
                                <div style="display: flex; align-items: center;">
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="stop(item)">
                                        </template>
                                        <span>עצור</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_pause.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="pause(item)">
                                        </template>
                                        <span>הפסק</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_play.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="play(item)">
                                        </template>
                                        <span>הפעל</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_download_file.svg" v-on="on"
                                                style="margin: 10px; width: 20px; cursor: pointer;" class="audioButtons"
                                                @click="download(item)">
                                        </template>
                                        <span>הורדה</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_forward_file.svg" v-on="on"
                                                style="margin: 10px; width: 20px; cursor: pointer;" class="audioButtons"
                                                @click="forward(item)">
                                        </template>
                                        <span>העברה לשלוחת הודעות</span>
                                    </v-tooltip>
                                </div>
                            </td>
                        </tr>
                    </template>

                    <!-- this for phone mode -->
                    <template v-slot:item.type="{ item, index }">
                        <div style="display: flex; justify-content: space-between;">
                            {{ item.type }}
                            <v-tooltip bottom color="#0d2c6d" v-if="item.type === 'הודעה משותפת'">
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_details.svg" v-on="on" style="cursor: pointer;">
                                </template>
                                <span style="color: white;">שותף ממערכת מספר: {{ item.shared_from_ivr }}</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <!-- this for phone mode -->
                    <template v-slot:item.record_name="{ item, index }">
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                    style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                    @click="stop(item)">
                            </template>
                            <span>עצור</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_pause.svg" v-on="on"
                                    style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                    @click="pause(item)">
                            </template>
                            <span>הפסק</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_play.svg" v-on="on"
                                    style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                    @click="play(item)">
                            </template>
                            <span>הפעל</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_download_file.svg" v-on="on"
                                    style="margin-bottom: 5px; width: 20px; cursor: pointer;" class="audioButtons"
                                    @click="download(item)">
                            </template>
                            <span>הורדה</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_forward_file.svg" v-on="on"
                                    style="margin: 10px; width: 20px; cursor: pointer;" class="audioButtons"
                                    @click="forward(item)">
                            </template>
                            <span>העברה לשלוחת הודעות</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין
                                נתונים
                                למנוי זה</span>
                        </div>
                        <v-btn color="#0d2c6d" dark @click="refreshDetails()" style="margin-bottom: 10px;">
                            Reset
                        </v-btn>
                    </template>

                </v-data-table>

                <template>
                    <v-overlay :value="overlay">
                        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
                    </v-overlay>
                </template>

            </div>
        </div>

        <ListeningStatisticDialog v-model="listeningStatisticDialog" v-if="listeningStatisticDialog"
            :listeningDetails="listeningDetails" :recordDuration="recordDuration" />

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import utils from '@/util/utils'
import Auth from '@/services/auth.service.js'
import ListeningStatisticDialog from '@/components/upload/dialogs/ListeningStatisticDialog'

export default {
    components: {
        SnackBar,
        ListeningStatisticDialog
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        progressShow: false,
        lineNumber: "",
        records: [],
        listeningStatisticDialog: false,
        listeningDetails: [],
        recordDuration: "",
        overlay: false,
        search: "",
        allertDelete: true,
        headers: [
            { text: "קובץ מס'", value: "integer_id" },
            { text: 'שם', align: 'start', value: 'caller_name' },
            { text: 'טלפון', value: 'caller_id' },
            { text: 'שלוחה', value: 'type' },
            { text: 'תאריך הקלטה', value: 'createdAt' },
            { text: 'זמן הקלטה', value: 'record_duration' },
            { text: 'שם הקלטה', value: 'record_name_original' },
            { text: 'שמע', align: "center", value: 'record_name' },
        ],
    }),
    methods: {

        async getRecords() {
            try {
                this.progressShow = true;

                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/records/get_stucked_records`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.records = jsonObject;
                    this.formatDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.records = this.records.map((record, index) => {
                return {
                    ...record,
                    createdAt: utils.getCurrentDate(record.createdAt),
                    record_duration_original: record.record_duration,
                    record_duration: this.formatDuration(record.record_duration),
                    type: record.type === "regular" ? "שלוחת הודעות" : record.type === "private" ? "הודעה פרטית" : "הודעה משותפת",
                    caller_id: record.caller_id === "WEBSITE" ? "אתר הניהול" : record.caller_id,
                    position: index + 1 // Adding a position property
                }
            });
        },
        async setAudio(record) {
            try {
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/` + record.record_name;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.blob();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בהפעלת ההקלטה", "red");
                } else if (res.status === 200) {
                    const url = URL.createObjectURL(jsonObject);
                    record.record_link = url;
                    record.record_audio = new Audio(url);
                    const index = this.records.findIndex(record1 => record1._id === record._id);
                    let g = Object.assign(this.records[index], record);
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;

            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = sec.toString().padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async download(item) {
            if (!item.record_audio) {
                this.overlay = true;
                await this.setAudio(item);
                this.overlay = false;
            }
            // Create a link for downloading
            const downloadLink = document.createElement("a");
            downloadLink.href = item.record_link; // URL to the audio file
            downloadLink.download = item.record_name; // The name of the downloaded file

            // Append link to the body, click it, and then remove it
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

        },
        async play(item) {
            if (!item.record_audio) {
                this.overlay = true;
                await this.setAudio(item);
                this.overlay = false;
            }

            item.record_audio.play();
            item.record_audio.addEventListener('ended', () => {
                this.stop(item);
            });
            item.record_audio.addEventListener('error', (event) => {
                this.stop(item);
                this.showSnackBar('שגיאה: קובץ שמע לא תקין', "red");
            });
        },
        stop(item) {
            if (item.record_audio) {
                item.record_audio.pause();
                item.record_audio.currentTime = 0;
            }
        },
        pause(item) {
            if (item.record_audio) {
                item.record_audio.pause();
            }
        },
        async forward(item) {
            try {
                this.progressShow = true;
                const datesJSON = JSON.stringify({ record: item});
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/records/forward_stucked_to_exten_1`;
                const res = await fetch(api, ApiServices.requestOptions("POST", datesJSON, token));
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בהעברת הקובץ", "red");
                    this.progressShow = false;
                } else if (res.status === 200) {
                    this.progressShow = false;
                    const index = this.records.findIndex(record => record._id == item._id);
                    this.records.splice(index, 1);
                }
            } catch (error) {
                this.showSnackBar("Error forward file: " + error, "red");
            }
        }
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getRecords();
        setTimeout(() => {
            this.allertDelete = false;
        }, 5000);
    },
}
</script>
<style scoped>
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */


#boxContainer {
    width: 95%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 39px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 16px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    